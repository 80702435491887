<template>
  <v-row>
    <v-col cols="12">
      <invoice-form-top :key="`top.${sKey}`" :read-only="readOnly" />
    </v-col>

    <v-col class="mt-3" cols="12">
      <invoice-config
        :hide-movement-type-select="hideMovementTypeSelect"
        :read-only="readOnly"
      />
    </v-col>

    <v-col v-if="!hideLabels" class="text-end" cols="12">
      <invoice-labels-dialog field="InfoAdicionalDoc" />
      <invoice-labels-dialog field="InfoAdicionalEmisor" />
      <invoice-labels-dialog field="InfoAdicional" />
    </v-col>

    <invoice-references :key="`references.${sKey}`" :read-only="readOnly" />

    <v-col v-if="showItemsComponent" class="mt-3" cols="12">
      <slide-y-up-transition :delay="300" tag="div">
        <component :is="sItemsComponent" :read-only="readOnly" />
      </slide-y-up-transition>
    </v-col>

    <v-col v-if="!hideTotals" class="mt-3" cols="12">
      <invoice-totals :read-only="readOnly" />
    </v-col>

    <v-col cols="12">
      <invoice-addenda :hide-labels="hideLabels" :read-only="readOnly" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Mixins, Prop, VModel } from "vue-property-decorator";
import type {
  CompanyMovementType,
  InvoiceMovementTypeData,
  InvoiceTypeData,
} from "@planetadeleste/vue-mc-gw";
import {
  Invoice,
  InvoiceMovementType,
  InvoiceType,
} from "@planetadeleste/vue-mc-gw";
import { EventBus } from "@/services/event-bus";
import type { AxiosRequestConfig } from "axios";
import { route } from "@/services/laroute";

import { SlideYUpTransition } from "vue2-transitions";

import InvoiceConfig from "@/modules/invoices/components/rows/Config.vue";
import InvoiceItems from "@/modules/invoices/components/rows/Items.vue";
import InvoiceTotals from "@/modules/invoices/components/rows/Totals.vue";
import InvoiceAddenda from "@/modules/invoices/components/rows/Addenda.vue";
import InvoiceReferences from "@/modules/invoices/components/rows/References.vue";
import InvoiceFormTop from "@/modules/invoices/components/rows/Top.vue";
import InvoiceReceiptItems from "@/modules/invoices/components/receipt/ReceiptItems.vue";
import InvoiceDebtItems from "@/modules/invoices/components/debt/DebtItems.vue";
import InvoiceDebtReceiptItems from "@/modules/invoices/components/debt/DebtReceiptItems.vue";
import { get, uniqueId } from "lodash";
import { number } from "mathjs";
import { InvoiceModule } from "@/store/invoice";
import InvoiceMixin from "@/modules/invoices/mixins/InvoiceMixin";
import InvoiceLabelsDialog from "@/modules/invoices/components/InvoiceLabelsDialog.vue";

@Component({
  components: {
    InvoiceLabelsDialog,
    SlideYUpTransition,
    InvoiceConfig,
    InvoiceItems,
    InvoiceTotals,
    InvoiceAddenda,
    InvoiceReferences,
    InvoiceFormTop,
    InvoiceReceiptItems,
    InvoiceDebtItems,
    InvoiceDebtReceiptItems,
  },
})
export default class InvoicesSettings extends Mixins(InvoiceMixin) {
  @VModel({ type: Object, default: () => new Invoice() })
  obInvoiceModel!: Invoice;
  @Prop(Boolean) readonly readOnly!: boolean;
  @Prop(Boolean) readonly hideMovementTypeSelect!: boolean;

  sKey = uniqueId("invoice");

  get obInvoiceMovementType(): Partial<InvoiceMovementTypeData> {
    return InvoiceModule.invoiceMovementType;
  }

  get obInvoiceType(): Partial<InvoiceTypeData> {
    return this.invoiceType;
  }

  get sInvoiceTypeCode(): number | null {
    return this.obInvoiceType
      ? number(get(this.obInvoiceType, "code", 0))
      : null;
  }

  get showItemsComponent() {
    if (!this.iMovementType) {
      return false;
    }

    if (this.sItemsComponent !== "invoice-items") {
      return true;
    }

    return !!this.priceListId && !!this.invoiceCurrencyId && !!this.customerId;
  }

  get sItemsComponent() {
    let sComponentName = "invoice-items";

    if (this.isReceipt) {
      sComponentName = "invoice-receipt-items";
    } else if (
      this.isDebt ||
      this.isPay ||
      this.iMovementType === InvoiceMovementType.CODE_CANCEL_DEBT // DEBT or CANCEL DEBT
    ) {
      sComponentName =
        this.sInvoiceTypeCode === 701
          ? "invoice-debt-receipt-items"
          : "invoice-debt-items";
    }

    return sComponentName;
  }

  /**
   * @returns {boolean} Hide totals on e-Remito invoice type
   */
  get hideTotals(): boolean {
    return this.sInvoiceTypeCode === InvoiceType.CODE_EREMITO;
  }

  /**
   * Returns a boolean value indicating whether labels should be hidden.
   * @returns {boolean} True if labels should be hidden, false otherwise.
   */
  get hideLabels(): boolean {
    return (
      !this.iMovementType ||
      [
        InvoiceMovementType.CODE_DEBT,
        InvoiceMovementType.CODE_PAY,
        InvoiceMovementType.CODE_CANCEL_DEBT,
        InvoiceMovementType.CODE_DEBT_RYA,
      ].includes(this.iMovementType)
    );
  }

  mounted() {
    const obInvoiceMovementType: Partial<InvoiceMovementTypeData> =
      this.obInvoice.get("invoice_movement_type");
    if (obInvoiceMovementType && obInvoiceMovementType.code) {
      this.onChangeMovementType(obInvoiceMovementType);
    }

    this.onUpdateKey();
  }

  created() {
    EventBus.on("invoice.movement_type.changed", this.onChangeMovementType);
    EventBus.on("invoice.change.price", this.onUpdateKey);
    EventBus.on("invoice.customer.changed", this.onChangeCustomer);
  }

  beforeDestroy() {
    EventBus.off("invoice.movement_type.changed", this.onChangeMovementType);
    EventBus.off("invoice.change.price", this.onUpdateKey);
    EventBus.off("invoice.customer.changed", this.onChangeCustomer);
  }

  onUpdateKey() {
    this.sKey = uniqueId("invoice");
  }

  onChangeCustomer() {
    if (this.obInvoiceMovementType) {
      this.onChangeMovementType(this.obInvoiceMovementType);
    }
  }

  async onChangeMovementType(
    obModel: InvoiceMovementType | Partial<InvoiceMovementTypeData>
  ) {
    if (
      !obModel.code ||
      !obModel.id ||
      obModel.id === this.obInvoiceMovementType.id
    ) {
      this.setInvoiceType();
      this.setInvoicePriceWithTax(this.obInvoiceMovementType);

      return;
    }

    // this.sInvoiceMovementCode = this.$_.toNumber(obModel.code);
    this.obInvoice.set("invoice_movement_type_id", number(obModel.id));
    this.obInvoice.set("invoice_movement_type_code", number(obModel.code));
    this.obInvoice.set(
      "invoice_movement_type",
      obModel instanceof InvoiceMovementType ? obModel.attributes : obModel
    );
    this.obInvoice.set("invoice_type", null);
    this.obInvoice.set("invoice_type_id", null);
    this.obInvoice.set("price_with_tax", false);

    // Get and set invoice price_with_tax from movement
    this.setInvoicePriceWithTax(obModel);

    // Preload invoice type
    this.setInvoiceType();
    this.obInvoice.sync();
    this.onUpdateKey();
  }

  /**
   * Sets the invoice price with tax based on the provided movement type model.
   *
   * @param {InvoiceMovementType | Partial<InvoiceMovementTypeData>} obModel - The movement type model to determine the price with tax.
   * @return {void} No return value, sets the price_with_tax property of the invoice object.
   */
  setInvoicePriceWithTax(
    obModel: InvoiceMovementType | Partial<InvoiceMovementTypeData>
  ): void {
    const obCompanyMovements = this.companyMovementTypeConfig.filter(
      (obItem: CompanyMovementType) => {
        return (
          obItem.movement_type_config.invoice_movement_type_id === obModel.id
        );
      }
    );

    if (obCompanyMovements.length) {
      let obMovement: CompanyMovementType | undefined | null = null;
      if (obCompanyMovements.length === 1) {
        obMovement = obCompanyMovements.first() as CompanyMovementType;
      } else {
        obMovement = obCompanyMovements.find(
          (obItem: CompanyMovementType) =>
            obItem.movement_type_config.is_cash === this.obInvoice.is_cash
        ) as CompanyMovementType;
      }

      if (obMovement) {
        this.obInvoice.set("price_with_tax", obMovement.price_with_tax);
      }
    }
  }

  setInvoiceType() {
    if (this.obInvoice.id || !this.obInvoice.customer_id) {
      return;
    }

    const arInvoiceTypeList = get(
      this.obInvoiceMovementType,
      "invoice_types",
      []
    ) as InvoiceTypeData[];
    if (arInvoiceTypeList.length === 1) {
      const obInvoiceTypeData = arInvoiceTypeList[0];
      if (obInvoiceTypeData) {
        this.obInvoice.set("invoice_type", obInvoiceTypeData);
        this.obInvoice.set("invoice_type_id", obInvoiceTypeData.id);
        return;
      }
    }

    const obData = this.obInvoice.attributes;
    const obConfig: AxiosRequestConfig = {
      url: route("invoices.get_type", obData),
      method: "POST",
      data: obData,
    };
    const obInvoice = new Invoice();
    obInvoice
      .createRequest(obConfig)
      .send()
      .then((response) => {
        if (response.getData()) {
          const obResponseData: Partial<InvoiceTypeData> =
            response.getData().data;
          if (obResponseData.code && obResponseData.id) {
            this.obInvoice.set("invoice_type", obResponseData);
            this.obInvoice.set("invoice_type_id", obResponseData.id);
          }
        }
      });
  }
}
</script>
